<script lang="ts" setup>
import { ChevronDownIcon } from '@cfgtech/icons'

const props = withDefaults(defineProps<{
  opened: boolean
  headerText?: string
  index: number
  headClass?: string
  bodyClass?: string
}>(), {
  headerText: '',
  headClass: '',
  bodyClass: '',
})

const emit = defineEmits<{
  'click:header': [number]
}>()

function onHeadClick() {
  emit('click:header', props.index)
}
</script>

<template>
  <li>
    <!-- Use to set static content, for example custom backdrop -->
    <slot name="static" />

    <header
      class="flex cursor-pointer justify-between py-3"
      :class="{
        'text-brand': opened,
        'text-dark-200': !opened,
        [headClass]: headClass,
      }"
      @click="onHeadClick"
    >
      <slot name="head">
        <span
          v-if="headerText"
          class="shrink grow text-md font-highlighted"
        >
          {{ headerText }}
        </span>
      </slot>

      <ClientOnly>
        <span
          class="shrink-0 transition-all duration-300"
          :class="{ 'rotate-180': opened }"
        >
          <ChevronDownIcon />
        </span>
      </ClientOnly>
    </header>

    <main :class="{ [bodyClass]: bodyClass }">
      <LazyBaseCollapsable
        :closed-height="0"
        :is-opened="opened"
        :use-button="false"
      >
        <slot />
      </LazyBaseCollapsable>
    </main>
  </li>
</template>
